import { useState, useEffect } from 'react';

import { useApp } from '../../Data/AppContext';
// Todo: Компонент вообще переделать, подумать как сделать кастомный хук для определения соотношения и перерендера этих блоков без двойного рендера как сейчас!
const ContentBg = ({
    isAuthenticated,
    toggleSidebarHadler,
    toggleBlurHandler,
    toggleShelterHandler,
    showingWelcomeScreen,
}) => {
    const { userData, showWelcome, userItemsFetched, setShowWelcome } =
        useApp();

    const [lastSetupAuth, setLastSetupAuth] = useState(false);
    const [initialSetup, setInitialSetup] = useState(false);

    useEffect(() => {
        if (
            !showingWelcomeScreen &&
            (lastSetupAuth !== isAuthenticated || !initialSetup)
        ) {
            setInitialSetup(true);
            setLastSetupAuth(isAuthenticated);

            const url = window.location.pathname;

            if (url === '/') {
                toggleBlurHandler(false);
                toggleSidebarHadler(false);
                return;
            }

            toggleShelterHandler(false);
            toggleBlurHandler(true);
        }
    }, [
        showingWelcomeScreen,
        initialSetup,
        lastSetupAuth,
        isAuthenticated,
        toggleBlurHandler,
        toggleShelterHandler,
        toggleSidebarHadler,
    ]);

    useEffect(() => {
        if (
            userData &&
            isAuthenticated &&
            showWelcome &&
            userItemsFetched &&
            setShowWelcome &&
            toggleSidebarHadler &&
            toggleBlurHandler &&
            toggleShelterHandler
        ) {
            setTimeout(() => {
                setShowWelcome(false);

                const url = window.location.pathname;

                if (url === '/') {
                    toggleSidebarHadler(false);
                    toggleBlurHandler(false);
                    toggleShelterHandler(true);
                }
            }, 2000);
        }
    }, [
        userData,
        isAuthenticated,
        showWelcome,
        userItemsFetched,
        setShowWelcome,
        toggleSidebarHadler,
        toggleBlurHandler,
        toggleShelterHandler,
    ]);

    return null;
};

export default ContentBg;
