import React from 'react';

export const Description = ({ template_id }) => {
    let data = null;

    switch (template_id) {
        case 450049:
        case 529506:
            data = (
                <>
                    <p>
                        <strong>Contains:</strong>
                        <br />
                        Mine: 1 <br />
                        Randomly picked 3 of 3000 preminted mine modules cards;
                        <br />
                        May contain an Uncommon Area (Space Debris);
                        <br />
                        May contain a Silver VIP Card. Total of 5 cards are
                        available per drop.
                        <br />
                        <br />
                    </p>
                    <p>
                        <strong>Preminted cards:</strong>
                        <br />
                        Mine Stabilization Module
                        <br />
                        Mine Processing Module
                        <br />
                        Mine Educational Module
                        <br />
                        Mine Expanding Module <br />
                        Mine EMS Module <br />
                        <br />
                    </p>
                    <p>
                        <strong>Drawing probability by rarity:</strong>
                        <br />
                        Common — 80% (480)
                        <br />
                        Uncommon — 15% (90)
                        <br />
                        Rare — 3% (18)
                        <br />
                        Epic — 1.5% (9)
                        <br />
                        Legendary — 0.5% (3)
                        <br />
                        <br />
                    </p>
                </>
            );
            break;
        case 450052:
        case 529507:
            data = (
                <>
                    <p>
                        <strong>Contains:</strong>
                        <br />
                        Randomly picked 1 of 2000 preminted mine modules cards.
                        <br />
                        <br />
                    </p>
                    <p>
                        <strong>Preminted cards:</strong>
                        <br />
                        Mine Stabilization Module
                        <br />
                        Mine Processing Module
                        <br />
                        Mine Educational Module
                        <br />
                        Mine Expanding Module
                        <br />
                        Mine EMS Module
                        <br />
                        <br />
                    </p>
                    <p>
                        <strong>Drawing probability by rarity:</strong>
                        <br />
                        Common — 70%
                        <br />
                        Uncommon — 19%
                        <br />
                        Rare — 6 %
                        <br />
                        Epic — 3.5 %
                        <br />
                        Legendary — 1.5%
                        <br />
                        <br />
                    </p>
                </>
            );
            break;
        case 450048:
        case 529504:
            data = (
                <p>
                    <strong>Contains:</strong>
                    <br />
                    Mine: 1 <br />
                    May contain a Common Area (Rock Fields);
                    <br />
                    May contain a Bronze VIP Card. Total of 10 cards are
                    available per drop.
                    <br />
                    <br />
                </p>
            );
            break;
        default:
            data = null;
    }

    if (!data) return null;

    return (
        <>
            <div className="store-product-page-divider" />
            <div className="store-product-page-description">
                <div className="store-product-page-description-title">
                    Details
                </div>
                <div className="store-product-page-description-text">
                    {data}
                </div>
            </div>
        </>
    );
};
