import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Header } from './components/Header/Header';
import { Main } from './components/Main/Main';
import { Join } from './components/Join/Join';
import { DeepMine } from './components/DeepMine/DeepMine';
import { Footer } from './components/Footer/Footer';
import { useApp } from '../../Data/AppContext';

import './LandingPage.scss';
import { getReferrerCookie, setReferrerCookie } from '../../Services';

const LandingPage = () => {
    const { isAuthenticated } = useApp();
    const [referrerCode, setReferrerCode] = useState('');
    const [referrerName, setReferrerName] = useState('');
    const history = useHistory();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const newReferrerCode = urlParams.get('referrer');
        setReferrerCode(newReferrerCode);
        const oldReferrerCode = getReferrerCookie();
        if (newReferrerCode && !oldReferrerCode) {
            setReferrerCookie(newReferrerCode);
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            history.push('/Affiliate');
        }
    }, [isAuthenticated]);

    return (
        <div className="landing">
            <img
                className="landing__background"
                src="img/landing/main-bg.png"
                alt=""
            />
            <Header />
            <Main referrerName={referrerName} />
            <DeepMine />
            <Join />
            <Footer />
        </div>
    );
};

export default LandingPage;
