import React from 'react';
import { ReactComponent as WaxIcon } from '../../assets/img/icons/wax_icon_orange.svg';
import { ReactComponent as DmpIcon } from '../../assets/img/icons/dmp_icon.svg';

const WaxSum = ({ dmpBalance, waxBalance }) => {
    return (
        <div className="block_sum">
            <div className="wax_sum">
                <WaxIcon className="icon" />
                <div className="sum">
                    {Number(waxBalance.toString().replace(' WAX', '')).toFixed(
                        4
                    )}
                </div>
                <div className="type">WAX</div>
            </div>
        </div>
    );
};

export default WaxSum;
