import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { CancelButton } from '../AffiliateModal/CancelButton/CancelButton';
import { ConnectWaxButton } from '../../ConnectWaxButton';
import { useApp } from '../../../Data/AppContext';
import './CallToAuthModal.scss';

export const CallToAuthModal = () => {
    const { hidePopupHandler } = useApp();
    const location = useLocation();
    const title = sessionStorage.getItem('callToAuthTitle');
    const description = sessionStorage.getItem('callToAuthDescription');

    useEffect(() => {
        return () => {
            sessionStorage.removeItem('callToAuthTitle');
            sessionStorage.removeItem('callToAuthDescription');
        };
    }, []);

    return (
        <div className="confirm-wrapper">
            <div className="confirm-title">{title || 'Join the Shop'}</div>
            <div className="call-to-auth-modal-description confirm-text-base">
                {description ||
                    'Сonnect your wallet in order to buy the items from the shop.'}
            </div>
            <div className="call-to-auth-modal-description-small">
                If you don’t have a Wax Wallet yet, you can{' '}
                <a
                    href="https://medium.com/@deepmineworld/how-to-create-a-wallet-on-the-wax-blockchain-ae556388a2b5"
                    target="_blank"
                    rel="noreferrer"
                >
                    read here
                </a>{' '}
                how to set it up.
            </div>

            <div onClick={hidePopupHandler} className="confirm-submit">
                <ConnectWaxButton redirectUrlAfterConnect={location.pathname} />
            </div>
            <CancelButton />
        </div>
    );
};
