import React from 'react';
import classnames from 'classnames';

import './TransactionItem.scss';
import { switchereKey } from '../../Config/index';
import { WAX_EXPLORER_LINK, CURRENCY_TYPE, CURRENCIES } from '../../Constants';

const TransactionItem = ({
    id,
    date,
    amount,
    status,
    txId,
    clientId,
    quantity,
    lotId,
}) => {
    const transactionHistoryBloks = () => {
        window.open(`${WAX_EXPLORER_LINK}/${txId}`, '_blank');
    };

    const clientOrderId = () => {
        window.switchereSdk.init({
            partnerKey: switchereKey,
            orderNiceId: clientId,
            httpReturnSuccess: 'https://shop.deepmine.world/MyStats?success',
            httpReturnFailed: 'https://shop.deepmine.world/MyStats?fail',
            mode: 'modal',
        });
    };

    const dateFormat = new Date(date);
    let assetName = '';

    switch (lotId) {
        case 1:
            assetName = 'Mine Pack';
            break;
        case 2:
            assetName = 'Mine Module Pack';
            break;
        case 3:
            assetName = 'Ultimate Mine Pack';
            break;
        case 7:
            assetName = 'Common set';
            break;
        default:
            assetName = '';
    }

    const currency = CURRENCY_TYPE[CURRENCIES.USD];

    return (
        <tr className="transaction_table__line">
            <td className="table_id">
                <span title={id}>{id}</span>
            </td>
            <td>{dateFormat.toLocaleString()}</td>
            <td>{assetName}</td>
            <td>{quantity}</td>
            <td style={{ opacity: amount ? 1 : 0 }}>{currency + amount}</td>
            <td className="bloks-history" onClick={clientOrderId}>
                {status}
            </td>
            <td onClick={transactionHistoryBloks} className="bloks-history">
                {txId?.slice(0, 8)}
            </td>
        </tr>
    );
};

export default TransactionItem;
