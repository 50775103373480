import React from 'react';
import cn from 'classnames';
import LogoImg from './Logo.jsx';
import WaxLogo from './WaxLogo.jsx';
import { useApp } from '../../Data/AppContext';

const Header = () => {
    const { sidebar } = useApp();

    return (
        <header className={cn('header', { 'header--with-sidebar': sidebar })}>
            <div className="header__container">
                <div className="header__left">
                    <LogoImg />
                </div>
                <div className="header__middle" />
                <div className="header__right">
                    <WaxLogo />
                </div>
            </div>
        </header>
    );
};

export default Header;
