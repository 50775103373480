import axios from 'axios';
import { JsonRpc } from 'eosjs';
import { wait } from './utils';

export const ENDPOINTS = window.ENDPOINTS.wax;

const CONNECTION_COUNT_LIMIT = ENDPOINTS.length * 2;

const rpc = new JsonRpc(ENDPOINTS[0], { fetch });
const getEndpoint = () =>
    ENDPOINTS[ENDPOINTS.indexOf(rpc.endpoint) + 1] || ENDPOINTS[0];

export const fetchHistoryActions = async (connectionCount = 0) => {
    try {
        connectionCount++;
        return await axios.get('/api/store/history');
    } catch (error) {
        if (!error.message.includes('assertion failure')) {
            if (connectionCount >= CONNECTION_COUNT_LIMIT)
                throw new Error('NetworkError!');

            rpc.endpoint = getEndpoint();

            await wait(1);
            return await fetchHistoryActions(connectionCount);
        }

        throw new Error(error.message);
    }
};

export const fetchRows = async (
    { contract, scope, table, limit, lowerBound = null, upperBound = null },
    connectionCount = 0
) => {
    try {
        connectionCount++;
        const config = {
            json: true,
            code: contract,
            scope,
            table,
            limit,
            lower_bound: lowerBound,
            upper_bound: upperBound,
        };
        if (!lowerBound) delete config.lower_bound;
        if (!upperBound) delete config.upper_bound;

        return await rpc.get_table_rows(config);
    } catch (error) {
        if (!error.message.includes('assertion failure')) {
            if (connectionCount >= CONNECTION_COUNT_LIMIT)
                throw new Error('NetworkError!');

            rpc.endpoint = getEndpoint();

            await wait(1);
            return await fetchRows(
                {
                    contract,
                    scope,
                    table,
                    limit,
                    lowerBound,
                    upperBound,
                },
                connectionCount
            );
        }

        throw new Error(error.message);
    }
};

export const getTableData = async ({ contract, scope, table }) => {
    const pageSize = 1000;
    let lowerBound = 0;
    let fetchMore = true;

    const assets = [];

    while (fetchMore) {
        const { rows, more, next_key } = await fetchRows({
            contract,
            scope,
            table,
            limit: pageSize,
            lowerBound,
        });

        assets.push(...rows);

        if (more) lowerBound = next_key;
        else fetchMore = false;
    }

    return assets;
};

export const signTransaction = async ({
    activeUser,
    account,
    action,
    data,
    expireSeconds = 30,
}) => {
    await activeUser.signTransaction(
        {
            actions: [
                {
                    account,
                    name: action,
                    authorization: [
                        {
                            actor: activeUser.accountName,
                            permission: 'active',
                        },
                    ],
                    data,
                },
            ],
        },
        {
            blocksBehind: 3,
            expireSeconds,
        }
    );
};

export const signTransactions = async ({ activeUser, actions }) => {
    await activeUser.signTransaction(
        {
            actions: actions.map(({ account, action, data }) => {
                return {
                    account,
                    name: action,
                    authorization: [
                        {
                            actor: activeUser.accountName,
                            permission: 'active',
                        },
                    ],
                    data,
                };
            }),
        },
        {
            blocksBehind: 3,
            expireSeconds: 30,
        }
    );
};
