import React, { useContext, useEffect, useState } from 'react';

import { UALContext } from 'ual-reactjs-renderer';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import cn from 'classnames';
import { useApp } from '../../Data/AppContext';
import { useRoutes } from '../../Hooks/Routes';

import { Modal, StakingModal } from '../../Components';

import {
    fetchWaxBalance,
    fetchTransactionHistory,
    affiliateAuth,
    getDiscordCode,
    getIsAffiliatePath,
} from '../../Services';
import AffiliateModal from '../../Components/Modal/AffiliateModal/AffiliateModal';
import { useAffiliate } from '../../Hooks/useModal';

const AppContent = () => {
    const routes = useRoutes();

    const {
        isAuthenticated,
        sidebar,
        userData,
        shelter,
        toggleRareFilter,
        toggleRareFilterHandler,
        toggleTermFilter,
        toggleTermFilterHandler,
        userLoginHandler,
        setUserDataHandler,
        unstakedListFetched,
        stakedListFetched,
        setWaxBalance,
        waxBalanceFetched,
        setTransactionInfo,
        transactionInfoFetched,
        setUserItemsFetched,
        userItemsFetched,
        showWelcome,
        setAuthData,
    } = useApp();

    const { activeUser } = useContext(UALContext);
    const [waxBalanceLoading, setWaxBalanceLoading] = useState(false);
    const [transactionInfoLoading, setTransactionInfoLoading] = useState(false);

    useEffect(() => {
        if (
            activeUser?.accountName &&
            setUserDataHandler &&
            userLoginHandler &&
            !isAuthenticated
        ) {
            setUserDataHandler(activeUser);
            userLoginHandler();
        }
    }, [activeUser, setUserDataHandler, userLoginHandler, isAuthenticated]);

    const updateAffiliateData = async () => {
        const authData = await affiliateAuth(activeUser.accountName);
        if (authData) setAuthData(authData);
    };

    const handleOnMount = async () => {
        if (activeUser?.accountName) {
            await updateAffiliateData();
        }
    };

    useEffect(() => {
        handleOnMount();
    }, [activeUser]);

    useEffect(() => {
        if (
            unstakedListFetched &&
            stakedListFetched &&
            !userItemsFetched &&
            setUserItemsFetched
        ) {
            setUserItemsFetched(true);
        }
    }, [
        unstakedListFetched,
        stakedListFetched,
        userItemsFetched,
        setUserItemsFetched,
    ]);

    useEffect(() => {
        if (
            activeUser &&
            activeUser.accountName &&
            setWaxBalance &&
            !waxBalanceLoading &&
            !waxBalanceFetched
        ) {
            setWaxBalanceLoading(true);

            fetchWaxBalance({ account: activeUser.accountName })
                .then((balance) => setWaxBalance(balance))
                .catch((e) => {
                    setWaxBalance(0);

                    toast.error(e.message);
                })
                .finally(() => setWaxBalanceLoading(false));
        }
    }, [activeUser, waxBalanceLoading, setWaxBalance, waxBalanceFetched]);

    useEffect(() => {
        if (
            activeUser &&
            activeUser.accountName &&
            setTransactionInfo &&
            !transactionInfoFetched &&
            !transactionInfoLoading
        ) {
            setTransactionInfoLoading(true);

            fetchTransactionHistory()
                .then((info) => setTransactionInfo(info))
                .catch((e) => {
                    setTransactionInfo([]);

                    toast.error(e.message);
                })
                .finally(() => setTransactionInfoLoading(false));
        }
    }, [
        activeUser,
        setTransactionInfo,
        transactionInfoFetched,
        transactionInfoLoading,
    ]);

    return (
        <div
            className={cn('app-wrapper', {
                'app-wrapper--with-sidebar': sidebar,
                not_auth: !isAuthenticated,
                auth: isAuthenticated && !showWelcome,
                success:
                    userData &&
                    isAuthenticated &&
                    userItemsFetched &&
                    showWelcome,
                shelter,
            })}
            onClick={() => {
                if (!toggleRareFilter && !toggleTermFilter) return;

                toggleRareFilterHandler(false);
                toggleTermFilterHandler(false);
            }}
        >
            {routes}
            <Modal />
            <StakingModal />
            <AffiliateModal />

            <ToastContainer
                containerId="notifications"
                toastClassName="notifications__toast"
                bodyClassName="notifications__body"
                position="bottom-left"
                autoClose={7000}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                hideProgressBar
            />
        </div>
    );
};

export default AppContent;
