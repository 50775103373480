import React, { useMemo, useState, useEffect } from 'react';
import classnames from 'classnames';
import { useApp } from '../../Data/AppContext';
import { ReactComponent as InfoIcon } from '../../assets/img/icons/icon.svg';
import { PageTitle } from '../../Components/Ui/v2';
import {
    ContentContainer,
    TransactionTable,
    Pagination,
} from '../../Components';
import { fetchTransactionHistory } from '../../Services';

import { SORT_OPTION } from '../../Constants';

const dataLimit = 10;

const MyStatsPage = () => {
    const { toggleSidebarHadler, transactionsInfo, setTransactionInfo } =
        useApp(false);

    const allItems = transactionsInfo;

    useEffect(() => {
        toggleSidebarHadler(false);

        fetchTransactionHistory()
            .then((items) => setTransactionInfo(items))
            .catch((e) => console.log(e));
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const [sortOption, setSortOption] = useState('');

    const sortedItems = useMemo(() => {
        return [...allItems].sort((item1, item2) => {
            switch (sortOption) {
                case SORT_OPTION.TYPE_ASC:
                    return item1.type.localeCompare(item2.type);

                case SORT_OPTION.TYPE_DESC:
                    return item2.type.localeCompare(item1.type);

                case SORT_OPTION.DATE_ASC:
                    return (
                        new Date(item1.created_at) - new Date(item2.created_at)
                    );

                case SORT_OPTION.DATE_DESC:
                    return (
                        new Date(item2.created_at) - new Date(item1.created_at)
                    );

                case SORT_OPTION.AMOUNT_ASC:
                    return item1.amount - item2.amount;

                case SORT_OPTION.AMOUNT_DESC:
                    return item2.amount - item1.amount;

                case SORT_OPTION.STATUS_ASC:
                    return item1.status.localeCompare(item2.status);

                case SORT_OPTION.STATUS_DESC:
                    return item2.status.localeCompare(item1.status);

                default:
                    return 0;
            }
        });
    }, [allItems, sortOption]);

    const itemsToShow = useMemo(() => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;

        return sortedItems.slice(startIndex, endIndex);
    }, [sortedItems, currentPage]);

    return (
        <ContentContainer>
            <PageTitle withoutSidebar>My Operations</PageTitle>
            <div className="stats_page">
                <div className="stats_page__content">
                    <h2 className="transaction__title">
                        Transaction History
                        <span className="transaction__title-tooltip">
                            <InfoIcon />
                        </span>
                        <div className={classnames('transaction__title-info')}>
                            Actions appear here after they are confirmed by
                            blockchain. Wait a couple of minutes then refresh a
                            page.
                        </div>
                    </h2>

                    <TransactionTable
                        transactions={itemsToShow}
                        sortOption={sortOption}
                        setSortOption={setSortOption}
                    />

                    <div style={{ margin: '30px 0 20px 0' }}>
                        <Pagination
                            pages={Math.ceil(allItems.length / dataLimit) || 1}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </div>
        </ContentContainer>
    );
};

export default MyStatsPage;
