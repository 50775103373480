import React, { useEffect, useState } from 'react';

import { Button } from '../../../Ui/v2';
import { ReactComponent as GoogleIcon } from '../../../../assets/img/icons/google-icon-yellow.svg';
import { CancelButton } from '../CancelButton/CancelButton';

import './EmailModal.scss';
import { useApp } from '../../../../Data/AppContext';
import { affiliateAuth } from '../../../../Services';
import { useAffiliate } from '../../../../Hooks/useModal';
import { useUser } from '../../../../Hooks/useUser';

const SECOND = 1000;
const EMAIL_RESENT_INTERVAL = 60;

export const EmailModal = () => {
    const {
        authLinks,
        isEmailSent,
        setIsEmailSent,
        authData,
        affiliateModalData,
        setAffiliateAchievements,
        setAuthData,
    } = useApp();
    const activeUser = useUser();
    const { showAffiliationPopup, hideAffiliationPopup } = useAffiliate();
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [isEmailResent, setIsEmailResent] = useState(false);
    const [timer, setTimer] = useState(undefined);

    const handleEmailChange = (e) => {
        setEmail(e.target.value.trim());
    };
    const handleCodeChange = (e) => {
        setCode(e.target.value.trim());
    };

    const handleConnectAnotherEmail = () => {
        setIsEmailSent(false);
        setCode('');
        setEmail('');
    };

    const handleResend = async () => {
        setIsEmailResent(true);
        setTimer(EMAIL_RESENT_INTERVAL);
    };

    const handleSubmitEmail = async () => {
        if (isEmailSent) {
            setAuthData(await affiliateAuth(activeUser.accountName));

            if (
                affiliateModalData?.shouldOpenDiscord &&
                !authData?.discord_confirmed
            ) {
                showAffiliationPopup('discord');
            } else {
                hideAffiliationPopup();
            }
            setIsEmailSent(false);
        }
    };
    const handleSubmitGoogle = async () => {
        try {
            window.open(authLinks.google, '_blank').focus();
        } catch (e) {
            showAffiliationPopup('email-error');
        }
    };

    useEffect(() => {
        if (timer === 0) {
            setIsEmailResent(false);
        } else {
            setTimeout(() => setTimer(timer - 1), SECOND);
        }
    }, [timer, setTimer, setIsEmailResent]);

    return (
        <div className="email-modal">
            <div className="affiliate-modal__title">
                {isEmailSent
                    ? 'Enter the code from an email'
                    : 'Bind your email address'}
            </div>
            <div className="affiliate-modal__description">
                {isEmailSent
                    ? 'Check your email, you should have received a verification code'
                    : 'We will send you a verification code'}
            </div>

            {isEmailSent ? (
                <>
                    <input
                        value={code}
                        className="email-modal__input"
                        onChange={handleCodeChange}
                        placeholder="Verification code"
                    />
                    <div className="email-modal__code-actions">
                        {isEmailResent ? (
                            <div className="email-modal__code-actions__resend">
                                Resend the code ({timer} seconds)
                            </div>
                        ) : (
                            <div
                                className="email-modal__code-actions__action"
                                onClick={handleResend}
                            >
                                Resend the code
                            </div>
                        )}
                        <div
                            className="email-modal__code-actions__action"
                            onClick={handleConnectAnotherEmail}
                        >
                            Bind another email
                        </div>
                    </div>
                    {isEmailResent && (
                        <div className="email-modal__code-actions__resend-info">
                            Didn't receive a code? Check your spam folder or try
                            to resend the code
                        </div>
                    )}
                </>
            ) : (
                <input
                    value={email}
                    className="email-modal__input"
                    onChange={handleEmailChange}
                    placeholder="Email"
                />
            )}

            <div>
                <Button
                    className="email-modal__button"
                    onClick={handleSubmitEmail}
                    disabled={
                        (!isEmailSent && email.length === 0) ||
                        (isEmailSent && code.length === 0)
                    }
                >
                    Bind email
                </Button>
                <div className="email-modal__hr-container">
                    <div className="email-modal__hr" />
                    <span>or</span>
                    <div className="email-modal__hr" />
                </div>
                <Button
                    className="email-modal__button"
                    onClick={handleSubmitGoogle}
                >
                    <GoogleIcon />
                    <span>Bind with Google</span>
                </Button>
            </div>

            <CancelButton />
        </div>
    );
};
