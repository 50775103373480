import axios from 'axios';
import { CONTRACT_NAMES } from '../Constants';
import { signTransaction } from '../Helpers';

export const buyPackDMP = async ({ activeUser, lotId, quantity }) => {
    return await signTransaction({
        activeUser,
        account: CONTRACT_NAMES.tokn,
        action: 'transfer',
        data: {
            from: activeUser.accountName,
            to: CONTRACT_NAMES.market,
            memo: lotId,
            quantity: `${quantity.toFixed(8)} DMP`,
        },
    });
};

export const buyPackUSD = async ({ activeUser, lotId, quantity }) => {
    return await axios.post('/api/store/order', {
        user: activeUser.id,
        quantity,
        lotId,
    });
};

export const buyPackVDMP = async ({ activeUser, lotId, quantity }) => {
    return await signTransaction({
        activeUser,
        account: CONTRACT_NAMES.market,
        action: 'vdmptransfer',
        data: {
            owner: activeUser.accountName,
            dmp_quantity: quantity * 100000000,
            memo: lotId,
        },
    });
};

export const buyPackWAX = async ({ activeUser, lotId, quantity }) => {
    return await signTransaction({
        activeUser,
        account: CONTRACT_NAMES.eosioToken,
        action: 'transfer',
        data: {
            from: activeUser.accountName,
            to: CONTRACT_NAMES.market,
            memo: lotId,
            quantity: `${quantity.toFixed(8)} WAX`,
        },
    });
};
