import React, { useEffect } from 'react';
import { switchereKey } from '../../../../Config/index';

export const Switchere = ({ order, closeCallback }) => {
    useEffect(() => {
        window.switchereSdk.init({
            partnerKey: switchereKey,
            partnerOrderId: order.id,
            httpReturnSuccess: 'https://shop.deepmine.world/MyStats?success',
            httpReturnFailed: 'https://shop.deepmine.world/MyStats?fail',
            payoutAmount: order.total,
            mode: 'modal',
            closeButton: true,
            closeButtonCallback: closeCallback,
        });
    });

    return '';
};
