import {
    CONTRACT_NAMES,
    CONTRACT_TABLE_NAMES,
    STAKING_DURATION_1_DAY,
    STAKING_DURATION_FIXED,
} from '../Constants';
import {
    countTotalMiningReward,
    fetchRows,
    fetchHistoryActions,
    getDataFromAtomicApi,
} from '../Helpers';

export const fetchWaxBalance = async ({ account }) => {
    const { rows } = await fetchRows({
        contract: CONTRACT_NAMES.eosioToken,
        scope: account,
        table: CONTRACT_TABLE_NAMES.accounts,
    });

    if (!rows[0]) return `0 WAX`;

    const [value, currency] = rows[0].balance.split(' ');

    return `${Number(value).toFixed(1)} ${currency}`;
};

export const fetchDmpBalance = async ({ account }) => {
    const { rows } = await fetchRows({
        contract: CONTRACT_NAMES.stak,
        scope: CONTRACT_NAMES.stak,
        table: CONTRACT_TABLE_NAMES.collected,
        lowerBound: account,
        upperBound: account,
        limit: 1,
    });

    if (!rows[0]) return `0 DMP`;

    return `${Number(rows[0].quantity).toFixed(4)} DMP`;
};

const getItemMiningReward = ({ itemID, stakingConfig, multiplier }) => {
    const rewardFromConfig = stakingConfig.find(
        ({ template_id }) => Number(template_id) === Number(itemID)
    );

    const dailyReward = rewardFromConfig?.reward ?? 0;

    return { dailyReward, preLaunchReward: dailyReward * multiplier ?? 0 };
};

export const fetchNotStakedItems = async ({
    account,
    miningMultiplier,
    stakingConfig,
}) => {
    const data = await getDataFromAtomicApi(
        `assets?collection_name=${CONTRACT_NAMES.game}&owner=${account}&page=1&limit=1000`
    );

    return data
        .filter(({ schema: { schema_name } }) =>
            ['equipment', 'areas', 'stickers'].includes(schema_name)
        )
        .map((item) => {
            const { dailyReward, preLaunchReward } = getItemMiningReward({
                itemID: item.template.template_id,
                stakingConfig,
                multiplier: miningMultiplier,
            });

            return {
                ...item,
                miningInfo: {
                    dailyPool: dailyReward,
                    preLaunchPool: preLaunchReward,
                },
            };
        });
};

const sliceArrayIntoChunks = (array) => {
    const perChunk = 500; // items per chunk + limit for assets per one request on atomic api

    return array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) resultArray[chunkIndex] = [];

        resultArray[chunkIndex].push(item);

        return resultArray;
    }, []);
};

export const fetchStakedItems = async ({
    account,
    miningMultiplier,
    stakingConfig,
    preLaunchFinishTimestamp,
}) => {
    const { rows } = await fetchRows({
        contract: CONTRACT_NAMES.stak,
        scope: CONTRACT_NAMES.stak,
        table: CONTRACT_TABLE_NAMES.stake,
        lowerBound: account,
        upperBound: account,
        limit: 1,
    });

    if (!rows[0]) return [];

    const stakedItems = [
        ...rows[0].staked_nfts_Fixed,
        ...rows[0].staked_nfts_1D,
    ];
    const stakedItemsChunks = sliceArrayIntoChunks(stakedItems);

    const assets = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const items of stakedItemsChunks) {
        const data = await getDataFromAtomicApi(
            `assets?ids=${items
                .map(({ asset_id }) => asset_id)
                .join(',')}&limit=1000&page=1`
        );

        assets.push(...data);
    }

    return assets.map((item) => {
        const stakingInfo = stakedItems.find(
            ({ asset_id }) => asset_id === item.asset_id
        );
        const isNftStakedForOneDay = rows[0].staked_nfts_1D.find(
            ({ asset_id }) => asset_id === item.asset_id
        );

        const stakingDuration = isNftStakedForOneDay
            ? STAKING_DURATION_1_DAY
            : STAKING_DURATION_FIXED;

        const { dailyReward, preLaunchReward } = getItemMiningReward({
            itemID: item.template.template_id,
            stakingConfig,
            multiplier: miningMultiplier,
        });

        const totalMiningReward = countTotalMiningReward({
            preLaunchFinishTimestamp,
            stakingDuration,
            startTimestamp: stakingInfo.initial_time_stake,
            rewardPerDay: Number(dailyReward),
        });

        return {
            ...item,
            stakingInfo: {
                ...stakingInfo,
                stakingDuration,
                rate: isNftStakedForOneDay ? dailyReward : preLaunchReward,
            },
            miningInfo: {
                totalMiningReward,
            },
        };
    });
};

export const fetchTransactionHistory = async () => {
    const data = await fetchHistoryActions();
    const response = data.data.map((item) => {
        item.status = item.status.toLowerCase();
        // .replace('transfer', 'Stake NFTs')
        return item;
    });

    const transactionHistory = [];

    response.forEach((transaction) => {
        transactionHistory.push(transaction);
    });

    return transactionHistory;
};
