import { TOKEN_TYPE } from '../Constants';

export * from './initEndpoints';
export * from './chain.helper';
export * from './staking.helper';
export * from './atomicApi.helper';
export * from './cards.helper';

export const formatNumber = (value, options = {}) =>
    Intl.NumberFormat('en', options).format(value);

export const getWaxCount = (price) => price / 100000000;
export const getUsdCount = (price) => price;
export const getVDmpCount = (price) => price / 100000000;

export const getBalanceCount = (type, price) => {
    switch (type) {
        case TOKEN_TYPE.WAX:
            return getWaxCount(price);
        default:
            return getUsdCount(price);
    }
};

export const pluralize = (count, text) => (count > 1 ? `${text}s` : text);
