import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Notice } from '../../../../Components/Ui/v2';

export const OutOfStock = () => (
    <Notice className="store-product-page-options__notice">
        This product is out of stock
        <p>Don't worry, we still have a lot of cool stuff</p>
        <RouterLink to="/shop">
            <Button style={{ marginTop: 12 }} use="secondary">
                Discover other products
            </Button>
        </RouterLink>
    </Notice>
);
