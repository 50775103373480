import React, { useContext } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { UALContext } from 'ual-reactjs-renderer';
import { ConnectWaxButton } from '../ConnectWaxButton';
import { Link } from '../Ui/v2';
import { ReactComponent as PurseImg } from '../../assets/img/icons/purse.svg';

const Information = () => {
    const { showModal } = useContext(UALContext);

    return (
        <div className="unauthorized_content">
            <div className="unauthorized_wrapper">
                <div className="connect_wallet">
                    <PurseImg className="connect_wallet__icon" />
                    <h3 className="connect_wallet__title">WAX Wallet</h3>
                    <span className="connect_wallet__text">
                        Please connect your wallet
                        <br />
                        to enter the Shop
                    </span>

                    <ConnectWaxButton />
                </div>
                <div className="information__container">
                    <div className="welcome-info">
                        <h2 className="welcome-info__title">
                            Howdy, stranger!
                        </h2>
                        <p className="welcome-info__text">
                            Welcome to the DeepMine NFT Shop.
                        </p>
                        <p className="welcome-info__text">
                            You need to{' '}
                            <span
                                className="welcome-info__connect-wax"
                                onClick={showModal}
                            >
                                connect your WAX wallet
                            </span>{' '}
                            in order to buy the items from the shop.
                        </p>
                        <p className="welcome-info__text">
                            If you don’t have a Wax Wallet yet, you can read{' '}
                            <Link
                                active
                                href="https://medium.com/@deepmineworld/how-to-create-a-wallet-on-the-wax-blockchain-ae556388a2b5"
                                target="_blank"
                                rel="noreferrer"
                            >
                                here
                            </Link>{' '}
                            how to set it up.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Information;
