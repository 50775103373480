import React from 'react';

import TransactionItem from './TransactionItem';

import { ReactComponent as ArrowBottomIcon } from '../../assets/img/icons/arrow_bot.svg';
import { ReactComponent as ArrowTopIcon } from '../../assets/img/icons/arrow_top.svg';

import { SORT_OPTION } from '../../Constants';

const TransactionTable = ({ transactions, sortOption, setSortOption }) => {
    return (
        <div className="transaction_block">
            <table className="transaction_table">
                <thead>
                    <tr className="transaction_table__header">
                        <th>Order_Id</th>

                        <th
                            onClick={() => {
                                setSortOption(
                                    sortOption === SORT_OPTION.DATE_DESC
                                        ? SORT_OPTION.DATE_ASC
                                        : SORT_OPTION.DATE_DESC
                                );
                            }}
                        >
                            Date{' '}
                            {sortOption === SORT_OPTION.DATE_ASC ? (
                                <ArrowTopIcon className="arrow_icon" />
                            ) : (
                                <ArrowBottomIcon className="arrow_icon" />
                            )}
                        </th>

                        <th>Assets</th>

                        <th>Qty</th>

                        <th>Amount</th>

                        <th
                            onClick={() => {
                                setSortOption(
                                    sortOption === SORT_OPTION.STATUS_DESC
                                        ? SORT_OPTION.STATUS_ASC
                                        : SORT_OPTION.STATUS_DESC
                                );
                            }}
                        >
                            Status{' '}
                            {sortOption === SORT_OPTION.STATUS_ASC ? (
                                <ArrowTopIcon className="arrow_icon" />
                            ) : (
                                <ArrowBottomIcon className="arrow_icon" />
                            )}
                        </th>
                        <th>Tx ID</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((item) => (
                        <TransactionItem
                            key={item.id}
                            id={item.id}
                            type={item.type}
                            assets={item.assets}
                            status={item.status}
                            date={item.created_at}
                            amount={item.total}
                            quantity={item.quantity}
                            lotId={item.lot_id}
                            clientId={item.client_order_id}
                            txId={item.transaction_id}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TransactionTable;
