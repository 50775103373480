/* eslint-disable no-nested-ternary */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { useApp } from '../Data/AppContext';
import {
    MyStatsPage,
    InformationPage,
    StorePage,
    CookiesPage,
    NotAuthPage,
} from '../Containers';
import { StoreProductPage } from '../Containers/StorePage/StoreProductPage';
import LandingPage from '../Containers/LandingPage/LandingPage';
import { ShelterContentContainer } from '../Containers/App/ShelterContentContainer';
import { MoreMenu } from '../Components/MobileMenu/MoreMenu';
import { useIsHaveOurAssets } from './useIsHaveOurAssets';

const RedirectToStore = () => <Redirect to="/shop" />;

export const useRoutes = () => {
    const { isAuthenticated, authData, userItemsFetched } = useApp();
    const isHaveOurAssets = useIsHaveOurAssets();

    const isNewEmptyUser = isAuthenticated && !isHaveOurAssets;

    const shelterRoutes = React.useMemo(
        () => [
            {
                path: '/',
                Component: RedirectToStore,
                shouldShow: true,
            },
            {
                path: '/shop',
                Component: StorePage,
                shouldShow: true,
            },
            {
                path: '/shop/:productId',
                Component: () => <StoreProductPage isHiddenSaleEndDetails />,
                shouldShow: true,
            },
            {
                path: '/MyStats',
                Component: isAuthenticated ? MyStatsPage : NotAuthPage,
                shouldShow: true,
            },
            {
                path: '/Information',
                Component: InformationPage,
                shouldShow: true,
            },
            {
                path: '/Cookies',
                Component: CookiesPage,
                shouldShow: true,
            },
        ],
        [isAuthenticated, isNewEmptyUser, authData, userItemsFetched]
    );

    return (
        <Switch>
            <Route exact path="/welcome">
                <LandingPage />
            </Route>

            {shelterRoutes.map(({ path, Component, shouldShow }) =>
                shouldShow ? (
                    <Route exact path={path} key={path}>
                        <ShelterContentContainer>
                            <Component />
                        </ShelterContentContainer>
                    </Route>
                ) : null
            )}

            <Route exact path="/MoreMenu">
                <MoreMenu />
            </Route>
            <Redirect to="/" />
        </Switch>
    );
};
