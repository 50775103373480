import React, { useEffect, memo, useState } from 'react';
import { toast } from 'react-toastify';
import { Counter } from '../Counter';
import { Button } from '../../../../Components/Ui/v2';
import { formatNumber, getBalanceCount } from '../../../../Helpers';
import { CURRENCY_TYPE, TOKEN_TYPE, CURRENCIES } from '../../../../Constants';
import { useCounter } from '../useCounter';
import { ButtonForCallToAuth } from './ButtonForCallToAuth';
import { useUser } from '../../../../Hooks/useUser';
import { buyPack } from '../../services/buy';
import { useApp } from '../../../../Data/AppContext';
import { Switchere } from './Switchere';
import { fetchTransactionHistory } from '../../../../Services';

export const orderId = 'itemsId';
export const itemsToBuyCount = 'itemsToBuyCount';

export const BuySection = memo(
    ({ isFormDisabled, card, itemsLeftUserCount }) => {
        const { isAuthenticated, setTransactionInfo } = useApp();
        const itemsLeft = Math.min(itemsLeftUserCount, card?.asset_ids.length);
        const countFromSessionStorage = Number(
            sessionStorage.getItem(itemsToBuyCount)
        );
        const { count, setCount, increment, decrement } = useCounter({
            max: itemsLeft,
            min: 1,
            initial: countFromSessionStorage || 1,
        });
        const priceType =
            card.price_type === TOKEN_TYPE.USD
                ? CURRENCIES.USD
                : CURRENCIES.WAX;
        const totalPrice = card.price_per_asset * count;
        const currency = CURRENCY_TYPE[priceType];
        const formattedAmount = formatNumber(
            getBalanceCount(priceType, totalPrice)
        );

        const activeUser = useUser();
        const [isCreateOrder, setIsCreateOrder] = useState(false);
        const [orderData, seOrderData] = useState({});

        useEffect(() => {
            sessionStorage.setItem(itemsToBuyCount, count);
        }, [count]);
        const handleInput = (event) => {
            setCount(event.currentTarget.value);
        };
        const closeCallback = (data) => {
            setIsCreateOrder(false);

            fetchTransactionHistory()
                .then((items) => setTransactionInfo(items))
                .catch((e) => console.log(e));
        };
        const handleSubmit = async (event) => {
            event.preventDefault();
            try {
                const order = await buyPack({
                    activeUser,
                    quantity: count,
                    card,
                });
                if (order) {
                    const { data } = order;
                    seOrderData(data);
                    setIsCreateOrder(true);
                    sessionStorage.setItem(orderId, data.id);
                }
            } catch (error) {
                toast.error(error.message);
            }
        };
        const buttonText = `Buy (${currency}${formattedAmount})`;

        return (
            <form
                className="store-product-page-options-form"
                onSubmit={handleSubmit}
            >
                <div className="store-product-page-options-counter">
                    <Counter
                        size="l"
                        onIncrement={increment}
                        onDecrement={decrement}
                        onInput={handleInput}
                        value={count}
                        disabled={isFormDisabled && isAuthenticated}
                    />
                </div>
                {isAuthenticated ? (
                    <Button
                        className="store-card__button"
                        type="submit"
                        size="l"
                        disabled={isFormDisabled}
                    >
                        {buttonText}
                    </Button>
                ) : (
                    <ButtonForCallToAuth text={buttonText} />
                )}
                {isCreateOrder && (
                    <Switchere
                        order={orderData}
                        closeCallback={closeCallback}
                    />
                )}
            </form>
        );
    }
);
