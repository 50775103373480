export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_USER_DATA = 'SET_USER_DATA';

export const USER_STAKED_ITEMS = 'USER_STAKED_ITEMS';
export const ADD_ITEMS_TO_USER_STAKED_ITEMS = 'ADD_ITEMS_TO_USER_STAKED_ITEMS';
export const REMOVE_ITEMS_FROM_USER_STAKED_ITEMS =
    'REMOVE_ITEMS_FROM_USER_STAKED_ITEMS';

export const USER_NOT_STAKED_ITEMS = 'USER_NOT_STAKED_ITEMS';
export const ADD_ITEMS_TO_USER_NOT_STAKED_ITEMS =
    'ADD_ITEMS_TO_USER_NOT_STAKED_ITEMS';
export const REMOVE_ITEMS_FROM_USER_NOT_STAKED_ITEMS =
    'REMOVE_ITEMS_FROM_USER_NOT_STAKED_ITEMS';

export const SELECTED_ITEMS_FOR_STAKE = 'SELECTED_ITEMS_FOR_STAKE';
export const SELECTED_ITEMS_FOR_UNSTAKE = 'SELECTED_ITEMS_FOR_UNSTAKE';

export const USER_ITEMS_FETCHED = 'USER_ITEMS_FETCHED';

export const SET_STAKING_CONFIG = 'SET_STAKING_CONFIG';

export const USER_WAX_BALANCE = 'USER_WAX_BALANCE';
export const USER_DMP_BALANCE = 'USER_DMP_BALANCE';

export const TRANSACTION_INFO = 'TRANSACTION_INFO';

export const SHOW_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';

export const DARKEN_SCREEN = 'DARKEN_SCREEN';
export const LIGHTEN_SCREEN = 'LIGHTEN_SCREEN';

export const TOGGLE_INTERFACE = 'TOGGLE_INTERFACE';

export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';

export const TOGGLE_BLUR = 'TOGGLE_BLUR';

export const TOGGLE_SHELTER = 'TOGGLE_SHELTER';
export const SCROLL_SHELTER = 'SCROLL_SHELTER';

export const TOGGLE_STAKING_POPUP = 'TOGGLE_STAKING_POPUP';
export const TOGGLE_REWARDS_POPUP = 'TOGGLE_REWARDS_POPUP';

export const TOGGLE_RARE_FILTER = 'TOGGLE_RARE_FILTER';
export const TOGGLE_TERM_FILTER = 'TOGGLE_TERM_FILTER';

export const SET_RARE_FILTER = 'SET_RARE_FILTER';
export const SET_TERM_FILTER = 'SET_TERM_FILTER';

export const SHOW_MORE = 'SHOW_MORE';

export const SHOW_WELCOME = 'SHOW_WELCOME';
export const SHOWING_WELCOME_SCREEN = 'SHOWING_WELCOME_SCREEN';

export const PRE_LAUNCH_FINISH_TIMESTAMP = 'PRE_LAUNCH_FINISH_TIMESTAMP';

export const MINING_MULTIPLIER = 'MINING_MULTIPLIER';

export const SET_AFFILIATE_MODAL_DATA = 'SET_AFFILIATE_MODAL_DATA';

export const SET_REFERRAL_DATA = 'SET_REFERRAL_DATA';
export const SET_AUTH_DATA = 'SET_AUTH_DATA';
export const SET_AUTH_LINKS = 'SET_AUTH_LINKS';
export const SET_IS_EMAIL_SENT = 'SET_IS_EMAIL_SENT';

export const SET_HAS_NTT = 'SET_HAS_NTT';

export const SET_AFFILIATE_ACHIEVEMENTS = 'SET_AFFILIATE_ACHIEVEMENTS';
