import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContentContainer } from '../../Components';
import { BackButton, Loader, PageTitle } from '../../Components/Ui/v2';
import { useApp } from '../../Data/AppContext';
import { useUser } from '../../Hooks/useUser';
import { StoreProductPageInfo, StoreProductPageOptions } from './components';
import { useStore } from './hooks/useStore';
import { useWhitelistPeriod } from './hooks/useWhitelistPeriod';
import { useUserStats } from './hooks/useUserStats';

const overflowYAuto = { overflowY: 'auto' };

export const StoreProductPage = ({ isHiddenSaleEndDetails }) => {
    const { productId } = useParams();
    const { accountName } = useUser() || {};
    const { data, isFetching, isSuccess, isError, refetch } =
        useStore(productId);

    const { toggleSidebarHadler } = useApp();
    const { data: whitelistPeriod } = useWhitelistPeriod();
    const userStats = useUserStats(accountName);

    useEffect(() => {
        toggleSidebarHadler(false);
    }, []);

    return (
        <ContentContainer>
            <PageTitle withoutSidebar>Store</PageTitle>

            <div className="store-page">
                <div className="store-page__content" style={overflowYAuto}>
                    <BackButton />
                    <main className="store-product-page-main">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {isError ? (
                            'Something went wrong'
                        ) : isFetching || !isSuccess ? (
                            <Loader
                                text="Loading item..."
                                style={{ margin: '0 auto' }}
                            />
                        ) : (
                            <>
                                <StoreProductPageInfo
                                    card={data[0]}
                                    accountName={accountName}
                                    userStats={userStats}
                                    whitelistPeriod={whitelistPeriod}
                                    isHiddenSaleEndDetails={
                                        isHiddenSaleEndDetails
                                    }
                                />
                                <StoreProductPageOptions
                                    card={data[0]}
                                    accountName={accountName}
                                    onUpdate={refetch}
                                    whitelistPeriod={whitelistPeriod}
                                    userStats={userStats}
                                />
                            </>
                        )}
                    </main>
                </div>
            </div>
        </ContentContainer>
    );
};
