import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import Countdown from 'react-countdown';
import { Button } from '../../../../Components/Ui/v2';
import { countdownRenderer } from './helpers';
import { getIsSaleCardActive } from '../../../../Helpers';
import { getCardStats } from '../../services/stats';
import { useUser } from '../../../../Hooks/useUser';
import { getStatus } from '../../services/status';
import { getMs } from '../../../../Helpers/utils';
import { CardImage } from './CardImage';
import { CardInfo } from './CardInfo';

// type: whitelist | public
const StoreCard = ({
    saleEnd,
    isInWhitelist,
    roundStartAt = -Infinity,
    userStats,
    type,
    card,
    isWide,
    context = 'shop',
    isHiddenSaleEndDetails,
}) => {
    const { accountName } = useUser() || {};
    const { isOutOfLimit } = getCardStats(userStats, accountName, card);

    const saleStartAt = getMs(card.start_at);
    const isRoundStart = Date.now() >= roundStartAt;
    const isSaleEnd = !getIsSaleCardActive({
        card,
        isWLRoundFinished: saleEnd,
        wlStart: roundStartAt,
    });
    const notInWhitelist = type === 'whitelist' && !isInWhitelist;

    const itemsLeft = card?.asset_ids.length || 0;
    const isOutOfStock = itemsLeft === 0;

    const status = getStatus(
        isSaleEnd,
        isOutOfStock,
        notInWhitelist,
        isOutOfLimit,
        isHiddenSaleEndDetails
    );

    const restriction =
        isSaleEnd ||
        isOutOfStock ||
        notInWhitelist ||
        isOutOfLimit ||
        !isRoundStart;

    const handleActionsClick = (event) => {
        if (restriction && !isHiddenSaleEndDetails) {
            event.preventDefault();
        }
    };

    const renderButton = () => {
        if (!isRoundStart || (Date.now() < saleStartAt && !isSaleEnd)) {
            return (
                <Button className="store-card__button" disabled>
                    Starts in (
                    <Countdown
                        renderer={countdownRenderer}
                        date={roundStartAt}
                    />
                    )
                </Button>
            );
        }

        if (
            (restriction && !isHiddenSaleEndDetails) ||
            isOutOfStock ||
            isOutOfLimit
        ) {
            return (
                <Button className="store-card__button" use="secondary">
                    See Details
                </Button>
            );
        }

        return (
            <Button className="store-card__button" use="secondary">
                Buy
            </Button>
        );
    };

    return (
        <Link
            to={`/${context}/${card.id}`}
            className={cn('store-card', {
                'store-card--wide': isWide,
                'store-card--restriction':
                    (restriction && !isHiddenSaleEndDetails) ||
                    isOutOfStock ||
                    isOutOfLimit,
            })}
        >
            <CardImage
                card={card}
                notInWhitelist={notInWhitelist}
                isOutOfStock={isOutOfStock}
                isOutOfLimit={isOutOfLimit}
                status={status}
                {...(!isHiddenSaleEndDetails && { isSaleEnd })}
            />
            <div className="store-card__bottom">
                <CardInfo card={card} itemsLeft={itemsLeft} />
                <div
                    className="store-card__action"
                    onClick={handleActionsClick}
                >
                    {renderButton()}
                </div>
            </div>
        </Link>
    );
};

export { StoreCard };
