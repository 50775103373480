import React from 'react';
import { getBalanceCount } from '../../../../Helpers';
import { CURRENCY } from '../../../../Constants';

import './CardInfo.scss';

export const CardInfo = ({ card, itemsLeft }) => (
    <div className="store-card__content">
        <p className="store-card__title">{card.name}</p>
        <span className="store-card__price">
            {CURRENCY[card.price_type]}
            {getBalanceCount(card.price_type, card.price_per_asset)}
        </span>
        <span className="store-card__left">{itemsLeft} items left</span>
    </div>
);
