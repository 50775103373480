import React from 'react';

import { RarityMarker } from '../../../../Components/Ui/v2';
import {
    CURRENCY_TYPE,
    rarityMap,
    TOKEN_TYPE,
    CURRENCIES,
} from '../../../../Constants';
import { ReactComponent as InfinityIcon } from '../../../../assets/img/icons/infinity.svg';
import { ReactComponent as DmpIcon } from '../../../../assets/img/icons/dmp_icon.svg';
import { ReactComponent as WaxIcon } from '../../../../assets/img/icons/wax_icon_orange.svg';
import { formatNumber, getBalanceCount } from '../../../../Helpers';

const OptionsCell = ({ title, content }) => (
    <div className="store-product-page-options-cell">
        <p>{title}</p>
        <span>{content}</span>
    </div>
);

const ICONS = {
    [TOKEN_TYPE.USD]: DmpIcon,
    [TOKEN_TYPE.WAX]: WaxIcon,
};

export const Info = ({ card }) => {
    const priceType =
        card.price_type === TOKEN_TYPE.USD ? CURRENCIES.USD : CURRENCIES.WAX;

    const currency = CURRENCY_TYPE[priceType];
    const itemsLeft = card?.asset_ids.length ?? 0;
    const itemsLeftTotal = card.initial_items_count;

    return (
        <div className="store-product-page-options-grid">
            {card.templates?.length <= 1 ? (
                <>
                    <OptionsCell
                        title="Template ID"
                        content={`#${card.template_id}`}
                    />
                    {card.rarity ? (
                        <OptionsCell
                            title="Rarity"
                            content={
                                <>
                                    <RarityMarker value={card.rarity} />{' '}
                                    {rarityMap[card.rarity]}
                                </>
                            }
                        />
                    ) : (
                        <div className="store-product-page-options-cell" />
                    )}
                    {card.template_mint !== '' &&
                        card.total_template_mints !== '' && (
                            <OptionsCell
                                title="Mint number"
                                content={
                                    <>
                                        {card.template_mint}
                                        {' of '}
                                        {card.total_template_mints} (max:{' '}
                                        {+card.max_supply_mint || (
                                            <InfinityIcon />
                                        )}
                                        )
                                    </>
                                }
                            />
                        )}
                </>
            ) : (
                ''
            )}
            <OptionsCell
                title="Price"
                content={
                    <div className="store-product-page-options__price-block">
                        <span
                            className={`store-product-page-options__price ${priceType}`}
                        >
                            {` ${currency}${formatNumber(
                                getBalanceCount(priceType, card.price_per_asset)
                            )}`}
                        </span>
                    </div>
                }
            />
            <OptionsCell title="Items left" content={`${itemsLeft}`} />
        </div>
    );
};
