import { useMemo } from 'react';

import { ReactComponent as Stats } from '../assets/img/menu/icon-stats.svg';
import { ReactComponent as Store } from '../assets/img/menu/icon-store.svg';
import { useApp } from '../Data/AppContext';
import { useIsHaveOurAssets } from './useIsHaveOurAssets';

export const useMenu = () => {
    const { isAuthenticated, authData, userItemsFetched } = useApp();
    const isHaveOurAssets = useIsHaveOurAssets();
    const isNewEmptyUser = isAuthenticated && !isHaveOurAssets;

    return useMemo(() => {
        const list = {
            top: [
                {
                    name: 'Shop',
                    mobileName: 'Shop',
                    path: '/shop',
                    exact: false,
                    icon: Store,
                    isNew: false,
                },
                {
                    name: 'My Operations',
                    mobileName: 'Operations',
                    path: '/MyStats',
                    exact: false,
                    icon: Stats,
                    isNew: false,
                },
            ],
            bottom: [],
        };

        list.bottom = [];

        return list;
    }, [isAuthenticated, authData, isNewEmptyUser, userItemsFetched]);
};
